import { useEffect, useState, useMemo } from 'react'
import Loader from '@byma/shared/components/Loader'
import { money, methodParser } from '@byma/shared/helpers/formatters'
import fuzzysort from 'fuzzysort'
import useFilters from '@byma/shared/hooks/useFilters'
import { useRouter } from 'next/router'
import fetcher from '@byma/shared/helpers/fetcher'
import useSWR from 'swr'
import useAuth from '@byma/shared/hooks/useAuth'

function CouponDetail({ coupon, handleClose }) {
    const session = useAuth()
    const { query, ...router } = useRouter()
    const { token, user: currentUser } = session
    const { queryString } = useFilters(query)
    const { data: couponDetail } = useSWR(token && coupon ? [`/api/coupons/report/${coupon._id.coupon}?${queryString}`, null, token] : null, fetcher, { revalidateOnFocus: false })
    console.log('coupon', coupon)
    return (
        <div className={`fixed w-full h-full inset-0 transition-opacity duration-100 ${coupon ? 'z-50 opacity-100' : '-z-1 opacity-0'}`} onClick={handleClose}>
            <div class="absolute w-full h-full bg-gray-900 opacity-50" />
            <div class="modal-content absolute bg-white w-full md:w-11/12 md:max-w-2xl md:mx-auto md:rounded md:shadow-lg z-50 md:h-3/4 h-full" onClick={(e) => e.stopPropagation()}>
                <div className="flex flex-col justify-between p-3 space-y-3 h-full">
                    <div className="">
                        <div className="flex justify-between items-center mb-3">
                            <div className="">
                                <p className="font-bold text-xl">Cupom {coupon?.coupon}</p>
                            </div>
                            <div className="cursor-pointer" onClick={handleClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-y-auto flex-grow flex-shrink">
                        {!couponDetail && <Loader />}
                        <table className="text-center relative border-separate whitespace-nowrap md:w-full">
                            <thead>
                                <tr>
                                    <th className="p-3 sticky top-0 bg-white">
                                        <p>Método de Pagamento</p>
                                    </th>
                                    <th className="p-3 sticky top-0 bg-white">
                                        <p>Vendas</p>
                                    </th>
                                    <th className="p-3 sticky top-0 bg-white">
                                        <p>Ingressos</p>
                                    </th>
                                    <th className="p-3 sticky top-0 bg-white">
                                        <p>Total</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {couponDetail?.result?.map((item, index) => (
                                    <tr className={`${index % 2 > 0 ? '' : 'bg-gray-100'} hover:bg-gray-200`}>
                                        <td className="p-3 w-full">
                                            <p className="font-bold text-left text-sm">{methodParser[item._id.method]}</p>
                                        </td>
                                        <td className="p-3">
                                            <p className="text-sm">{item.count}</p>
                                        </td>
                                        <td className="p-3">
                                            <p className="text-sm">{item.tickets}</p>
                                        </td>
                                        <td className="p-3">
                                            <p className="text-sm">{money(item.order_amount_cents/100)}</p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default function CouponsOverview({couponReport}) {
    const [ query, setQuery ] = useState('')
    const [ selectedRows, setSelectedRows ] = useState([])
    const [sortKey, setSortKey] = useState('tickets')
    const [sortOrder, setSortOrder] = useState(-1)
    const [couponDetail, setCouponDetail] = useState(null)

    const sortFn = {
        'tickets': (a,b) => b.tickets - a.tickets,
        'orders': (a,b) => b.orders - a.orders,
        'coupon': (a,b) => ('' + b.coupon).localeCompare(a.coupon),
    }

    useEffect(() => {
        if (couponReport?.result?.length) {
            selectAll()
        }
    }, [couponReport])

    function selectAll() {
        let _selectedRows = []
        couponReport?.result?.map((item) => {
            _selectedRows.push(item.coupon)
        })
        setSelectedRows(_selectedRows)
    }

    function toggleAll() {
        if (selectedRows.length === 0) {
            return selectAll()
        } 
        setSelectedRows([])
    }

    const items = useMemo(() => {
        if (!query || query.length === 0) {
            return couponReport?.result || []
        }
        const results = fuzzysort.go(query, couponReport?.result || [], {key: 'coupon'})
        return results.map(({obj}) => obj)
    }, [query, couponReport])

    const result  = useMemo(() => {
        if (sortOrder === -1) {
            return items.sort(sortFn[sortKey])
        } else {
            return items.sort(sortFn[sortKey]).reverse()
        }

    }, [items, sortKey, sortOrder])

    function handleSort(key) {
        if (sortKey === key) {
            setSortOrder(-1 * sortOrder)
        }
        setSortKey(key)
    }

    function toggleSelectedRow(_id) {
        if (selectedRows.indexOf(_id) >= 0) {
            setSelectedRows(selectedRows.filter((id) => id !== _id))
        } else {
            setSelectedRows([...selectedRows, _id])
        }
    }

    const calculator = useMemo(() => {
        return couponReport?.result?.reduce((acc, cur) => {
            if (selectedRows.indexOf(cur.coupon) >= 0) {
                return {
                    tickets: acc.tickets + cur.tickets,
                    orders: acc.orders + cur.orders,
                }
            }
            return acc
        }, { tickets: 0, orders: 0 })
    }, [selectedRows, couponReport])

    function toggleCouponDetail(coupon) {
        setCouponDetail(couponDetail ? null : coupon) 
    }

    return (
        <>
            <CouponDetail coupon={couponDetail} handleClose={() => setCouponDetail(null)} />
            <div className="w-full flex flex-col space-y-3">
                {!couponReport ? (<Loader />) : (
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0">
                        <div className="">
                            <div className="flex items-center rounded shadow px-2 py-1 hover:shadow-md whitespace-nowrap space-x-1 w-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                </svg>
                                <input className="w-full outline-none text-sm" placeholder="Buscar..." onChange={e => setQuery(e.currentTarget.value)} />
                            </div>
                        </div>
                        <div className="flex items-center rounded shadow px-2 py-1 whitespace-nowrap space-x-2 text-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z" clipRule="evenodd" />
                            </svg>
                            <div className="flex space-x-1">
                                <p className="font-bold">Vendas:</p>
                                <p>{calculator?.orders}</p>
                            </div>
                            <div className="flex space-x-1">
                                <p className="font-bold">Ingressos:</p>
                                <p>{calculator?.tickets}</p>
                            </div>
                        </div>
                    </div>
                )}
                {couponReport && result.length === 0 && (
                    <div className="p-3">
                        Nenhum resultado encontrado
                    </div>
                )}
                {couponReport && result.length > 0 && (
                    <div className="h-96 overflow-auto box p-0 whitespace-nowrap w-full">
                        <table className="text-center relative border-separate whitespace-nowrap md:w-full">
                            <thead>
                                <tr>
                                    <th className="p-3 sticky top-0 bg-white">
                                        <input type="checkbox" checked={selectedRows.length === couponReport?.result?.length} onClick={toggleAll} />
                                    </th>
                                    <th className="p-3 sticky top-0 bg-white">
                                        <button onClick={() => handleSort('coupon')} className="font-bold text-md flex w-full justify-center items-center space-x-3">
                                            <p>Cupom</p>
                                            { sortKey === 'coupon' && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform duration-200 transform-gpu ${sortOrder === -1 && 'rotate-180'}`} viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                            )}
                                        </button>
                                    </th>
                                    <th className="p-3 sticky top-0 bg-white">
                                        <button onClick={() => handleSort('orders')} className="font-bold text-md flex w-full justify-center items-center space-x-3">
                                            <p>Vendas</p>
                                            { sortKey === 'orders' && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform duration-200 transform-gpu ${sortOrder === -1 && 'rotate-180'}`} viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                            )}
                                        </button>
                                    </th>
                                    <th className="p-3 sticky top-0 bg-white">
                                        <button onClick={() => handleSort('tickets')} className="font-bold text-md flex w-full justify-center items-center space-x-3">
                                            <p>Ingressos</p>
                                            { sortKey === 'tickets' && (
                                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform duration-200 transform-gpu ${sortOrder === -1 && 'rotate-180'}`} viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                            )}
                                        </button>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {result?.map((item, index) => (

                                    <tr className={`${index % 2 > 0 ? '' : 'bg-gray-100'} hover:bg-gray-200`} onClick={() => toggleSelectedRow(item.coupon)} >
                                        <td className="w-min p-3">
                                            <input type="checkbox" checked={selectedRows.indexOf(item.coupon) >= 0}/>
                                        </td>
                                        <td className="p-3 w-full">
                                            <p className="font-bold text-left text-sm">{item.coupon || 'Sem cupom'}</p>
                                        </td>
                                        <td className="p-3">
                                            <p className="text-sm">{item.orders}</p>
                                        </td>
                                        <td className="p-3">
                                            <p className="text-sm">{item.tickets}</p>
                                        </td>
                                        <td className="p-3">
                                            {item.coupon && (
                                                <button onClick={() => toggleCouponDetail(item)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    )
}

