import { useEffect, useState, useMemo } from 'react'
import Loader from '@byma/shared/components/Loader'
import { money } from '@byma/shared/helpers/formatters'
import fuzzysort from 'fuzzysort'


export default function TicketTypesOverview({ticketReport}) {
    const [ query, setQuery ] = useState('')
    const [ selectedRows, setSelectedRows ] = useState([])
    const [sortKey, setSortKey] = useState('count')
    const [sortOrder, setSortOrder] = useState(-1)

    const sortFn = {
        'count': (a,b) => b.count - a.count,
        'total': (a,b) => (b.count * b.ticketType.cost) - (a.count * a.ticketType.cost),
        'name': (a,b) => ('' + b.ticketType.name).localeCompare(a.ticketType.name),
    }

    useEffect(() => {
        if (ticketReport?.result?.length) {
            selectAll()
        }
    }, [ticketReport])

    function selectAll() {
        let _selectedRows = []
        ticketReport?.result?.map((item) => {
            _selectedRows.push(item.ticketType._id)
        })
        setSelectedRows(_selectedRows)
    }

    function toggleAll() {
        if (selectedRows.length === 0) {
            return selectAll()
        } 
        setSelectedRows([])
    }

    const items = useMemo(() => {
        if (!query || query.length === 0) {
            return ticketReport?.result || []
        }
        let results = fuzzysort.go(query, ticketReport?.result || [], {key: 'ticketType.name'})
        return results.map(({obj}) => obj)
    }, [query, ticketReport])

    const result  = useMemo(() => {
        if (sortOrder === -1) {
            return items.sort(sortFn[sortKey])
        } else {
            return items.sort(sortFn[sortKey]).reverse()
        }

    }, [items, sortKey, sortOrder])

    function handleSort(key) {
        if (sortKey === key) {
            setSortOrder(-1 * sortOrder)
        }
        setSortKey(key)
    }

    function toggleSelectedRow(_id) {
        if (selectedRows.indexOf(_id) >= 0) {
            setSelectedRows(selectedRows.filter((id) => id !== _id))
        } else {
            setSelectedRows([...selectedRows, _id])
        }
    }

    const calculator = useMemo(() => {
        return ticketReport?.result?.reduce((acc, cur) => {
            if (selectedRows.indexOf(cur.ticketType._id) >= 0) {
                return {
                    count: acc.count + cur.count,
                    total: acc.total + (cur.ticketType.cost * cur.count)
                }
            }
            return acc
        }, { count: 0, total: 0 })
    }, [selectedRows, ticketReport])

    return (
        <div className="w-full flex flex-col space-y-3">
            {!ticketReport ? (<Loader />) : (
                <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0">
                    <div className="">
                        <div className="flex items-center rounded shadow px-2 py-1 hover:shadow-md whitespace-nowrap space-x-1 w-full">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                            <input className="w-full outline-none text-sm" placeholder="Buscar..." onChange={e => setQuery(e.currentTarget.value)} />
                        </div>
                    </div>
                    <div className="flex items-center rounded shadow px-2 py-1 whitespace-nowrap space-x-2 text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z" clipRule="evenodd" />
                        </svg>
                        <div className="flex space-x-1">
                            <p className="font-bold">Ingressos:</p>
                            <p>{calculator?.count}</p>
                        </div>
                        <div className="flex space-x-1">
                            <p className="font-bold">Total:</p>
                            <p>{money(calculator?.total)}</p>
                        </div>
                    </div>
                </div>
            )}
            {ticketReport && result.length === 0 && (
                <div className="p-3">
                    Nenhum resultado encontrado
                </div>
            )}
            {ticketReport && result.length > 0 && (
                <div className="h-96 overflow-auto box p-0 whitespace-nowrap w-full">
                    <table className="text-center relative border-separate whitespace-nowrap md:w-full">
                        <thead>
                            <tr>
                                <th className="p-3 sticky top-0 bg-white">
                                    <input type="checkbox" checked={selectedRows.length === ticketReport?.result?.length} onClick={toggleAll} />
                                </th>
                                <th className="p-3 sticky top-0 bg-white">
                                    <button onClick={() => handleSort('name')} className="font-bold text-md flex w-full justify-center items-center space-x-3">
                                        <p>Lote</p>
                                        { sortKey === 'name' && (
                                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform duration-200 transform-gpu ${sortOrder === -1 && 'rotate-180'}`} viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        )}
                                    </button>
                                </th>
                                <th className="p-3 sticky top-0 bg-white">
                                    <button onClick={() => handleSort('count')} className="font-bold text-md flex w-full justify-center items-center space-x-3">
                                        <p>Ingressos / Limite</p>
                                        { sortKey === 'count' && (
                                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform duration-200 transform-gpu ${sortOrder === -1 && 'rotate-180'}`} viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        )}
                                    </button>
                                </th>
                                <th className="p-3 sticky top-0 bg-white">
                                    <button onClick={() => handleSort('total')} className="font-bold text-md flex w-full justify-center items-center space-x-3">
                                        <p>Total</p>
                                        { sortKey === 'total' && (
                                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 transition-transform duration-200 transform-gpu ${sortOrder === -1 && 'rotate-180'}`} viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                        )}
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="w-full">
                            {result?.map((item, index) => (
                                <tr className={`${index % 2 > 0 ? '' : 'bg-gray-100'} hover:bg-gray-200`} onClick={() => toggleSelectedRow(item.ticketType._id)} >
                                    <td className="w-min p-3">
                                        <input type="checkbox" checked={selectedRows.indexOf(item.ticketType._id) >= 0}/>
                                    </td>
                                    <td className="p-3 w-full">
                                        <p className="font-bold text-left text-sm">{item.ticketType.name}</p>
                                    </td>
                                    <td className="p-3">
                                        <p className="text-sm">{item.count} / {item.ticketType.target || '?'} ({Math.round(item.count / item.ticketType.target * 100) || '?'}%)</p>
                                    </td>
                                    <td className="p-3">
                                        <p className="text-sm">{money(item.count * item.ticketType.cost)}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

