import React from "react"
export default function Tabs({tabs}) {
    const [activeTab, setActiveTab] = React.useState(0)
    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex space-x-3 overflow-x-auto">
                {tabs.map((tab, tabIndex) => (
                    <div className={`w-full md:w-48 border-b-2 ${activeTab === tabIndex && 'border-red-500'} px-2 pt-1 pb-3 cursor-pointer w-32`} onClick={() => setActiveTab(tabIndex)}>
                        <span className={`block text-center w-full rounded text-gray-800 px-2 py-1 ${activeTab === tabIndex ? 'font-bold' : 'hover:font-bold hover:bg-gray-100'}`}>
                            {tab.title}
                        </span>
                    </div>
                ))}
            </div>
            <div className="flex py-3 w-full h-full overflow-y-auto">
                {tabs[activeTab].content}
            </div>
        </div>
    )
}
